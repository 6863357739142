import React, { ReactElement, useState, useEffect, ReactNode, memo, Fragment, useCallback } from 'react';
import { Container, Divider, Paper, Grid, CardContent, Card, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import GoogleSignIn from '../../components/Integrations/GoogleSignIn';
import MicrosoftSignIn, { isSafeMicrosoftUrl } from '../../components/Integrations/MicrosoftSignIn';
import ZoomSignIn from '../../components/Integrations/ZoomSignIn';
import { getGrants } from '../../utils/authUtils';
import { RootState, Grant, actions, ThunkDispatchType } from '../../store';
import CabinetPage from '../../components/Common/CabinetPage';
import { MICROSOFT_CATEGORIES_INCREMENTAL_SCOPES, MICROSOFT_CATEGORY_SCOPE, 
  MICROSOFT_OAUTH_REDIRECT_URI, PAGE_URL, PROVIDER } from '../../constants';
import OAuthRedirectHandler from '../../components/Integrations/OAuthRedirectHandler';
import UpgradeOverlay from '../../components/Common/UpgradeOverlay/UpgradeOverlay';
import { CabButton, CabIcon } from '@CabComponents';
import IconLinkButton from '../../components/Common/IconLinkButton';
import { getMsLoginUrl } from '../../store/auth/actions';
import SalesforceSignIn from '../../components/Integrations/SalesforceSignIn';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IoAdd, IoAlertOutline } from 'react-icons/io5';

const calendarProviderOptions = [
  { provider: PROVIDER.GOOGLE.name, component: GoogleSignIn },
  { provider: PROVIDER.MICROSOFT.name, component: MicrosoftSignIn },
];
const conferenceProviderOptions = [{ provider: PROVIDER.ZOOM.name, component: ZoomSignIn }];
const crmProviderOptions = [{ provider: PROVIDER.SALESFORCE.name, component: SalesforceSignIn }];


export const IntegrationSettings = (): ReactElement => {

  const auth = useSelector((state: RootState) =>  state.auth);
  const schedule = useSelector((state: RootState) =>  state.schedule);
  const features = useSelector((state: RootState) =>  state.auth.user?.features);

  const dispatch = useDispatch<ThunkDispatchType>();

  const logoutOAuth = (grant: Grant) => dispatch(actions.auth.logoutOAuth(grant));
  const fetchZoomSettings = () => dispatch(actions.schedule.fetchZoomSettings());
  const fetchCalendars = () => dispatch(actions.schedule.fetchCalendars());

  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const [saving, setSaving] = useState(false);

  const grants = getGrants(auth);

  const calendarGrants = grants.filter(grant => [PROVIDER.GOOGLE.name, PROVIDER.MICROSOFT.name]
    .includes(grant.provider));
    
  const conferenceGrants = grants.filter(grant => [PROVIDER.ZOOM.name].includes(grant.provider));
  
  const crmGrants = grants.filter(grant => [PROVIDER.SALESFORCE.name].includes(grant.provider));

  useEffect(() => {
    fetchZoomSettings();
    fetchCalendars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(grants)]);

  const handleSignOut = (grant: Grant) => {
    setSaving(true);
    logoutOAuth(grant).then(() => {
      setSaving(false);
    });
  };

  const handleSignInDone = useCallback((providerId: number) => {
    if (providerId !== PROVIDER.ZOOM.id) {
      if (schedule.associationsLoaded && schedule.calendars.length === 0) {
        navigate(PAGE_URL.SCHEDULE);
      }
    } else { //if zoom
      setSearchParams("");
      navigate(PAGE_URL.INTEGRATION_SETTINGS, { /*search: ''*/ });
    }
    setSaving(false);
  }, [navigate, schedule.associationsLoaded, schedule.calendars.length, setSearchParams]);

  return (
    <OAuthRedirectHandler
      saving={saving}
      handleSignInStart={() => setSaving(true)}
      handleSignInDone={handleSignInDone}
    >
      <CabinetPage
        pageName={'IntegrationSettings'}
        headerContent={
          <Typography variant='h1' fontSize={24}>
            Account Management
          </Typography>
        }
      >

        <Box height='100%'>
          <Container maxWidth="lg" sx={{paddingTop: 4}}>
            <Grid container spacing={4} marginBottom={4}>
              <MemoizedIntegrationSection
                title="Calendar Accounts"
                providerOptions={calendarProviderOptions}
                grants={calendarGrants}
                handleSignInDone={handleSignInDone}
                handleSignOut={handleSignOut}
                id='IntegrationSettings-calendar-accounts'
              />

              <MemoizedIntegrationSection
                title="Virtual Conference Accounts"
                providerOptions={conferenceProviderOptions}
                grants={conferenceGrants}
                handleSignInDone={handleSignInDone}
                handleSignOut={handleSignOut}
                locked={!features?.ZOOM}
                id='IntegrationSettings-virtual-conference-accounts'
              />

              {process.env.NODE_ENV === 'development' &&
                <MemoizedIntegrationSection
                  title="CRM Accounts"
                  providerOptions={crmProviderOptions}
                  grants={crmGrants}
                  
                  handleSignInDone={handleSignInDone}
                  handleSignOut={handleSignOut}
                  locked={!features?.SALESFORCE}
                />
              }

            </Grid>
          </Container>
        </Box>
      </CabinetPage>
    </OAuthRedirectHandler>
  );
};


const IntegrationSection = ({
  title, simple, providerOptions, grants, handleSignInDone, handleSignOut, locked, id
}: {
  title?: string;
  simple?: boolean;
  id?: string;
  providerOptions: {
    provider: string;
    component: React.ComponentType<{
      onOAuthGranted: (providerId: number) => void;
      grant?: Grant;
    }>;
  }[];
  grants: Grant[];
  handleSignInDone: (providerId: number) => void;
  handleSignOut?: (grant: Grant) => void;
  locked?: boolean;
}): ReactElement => {
  const maxAccounts = 1;
  const [addAccount, setAddAccount] = useState<{ [provider: string]: boolean }>({});

  const dispatch = useDispatch<ThunkDispatchType>();

  const Wrapper = ({ children }: { children: ReactNode[] }) => simple ? (
    <Box display='flex' justifyContent='space-between' paddingTop={1.25} paddingBottom={1.25}>
      {children}
    </Box>
  ) : (
    <Card variant="outlined" id={id} sx={{width: '100%'}}>
      <CardContent>
        <Paper elevation={0} sx={{padding: '5px', position: 'relative'}}>
          {children}
          {
            locked && (
              <UpgradeOverlay
                show={locked}
                message={'Upgrade your subscription plan to unlock Integrations'} />
            )
          }
        </Paper>
      </CardContent>
    </Card>
  );

  const handleGetMSCategoryPermissions = (): void => {
    dispatch(getMsLoginUrl(
      undefined, 
      `${window.location.origin}${MICROSOFT_OAUTH_REDIRECT_URI}`, 
      MICROSOFT_CATEGORIES_INCREMENTAL_SCOPES
    )).then(authUrl => {
      if (!isSafeMicrosoftUrl(authUrl)) {
        console.error('Unsafe Microsoft URL detected:', authUrl);
        return;
      }
      window.location.href = authUrl;
    });
  };

  return (
    <Grid item xs={12}>
      <Wrapper>
        {title && (
          <Typography fontSize={18} fontWeight={500} marginLeft={0} alignSelf='center'>
            {title}
          </Typography>
        )}

        {providerOptions.map((provider, i) => {
          const providerName = provider.provider.charAt(0).toUpperCase() + provider.provider.slice(1);
          const providerGrants = grants.filter(grant => grant.provider === provider.provider);
          const ProviderComponent = provider.component;

          const showMicrosoftCategoryIncremental = (grant: Grant) => {
            if (provider.provider !== "microsoft") {
              return false;
            }

            const lowerCategoryScopes = MICROSOFT_CATEGORY_SCOPE.map(s => s.toLowerCase());

            const categoryGrantedScopes = grant.scope.filter(
              scope => lowerCategoryScopes.includes(scope.toLowerCase())
            );
            const allCategoryScopesGranted = categoryGrantedScopes.length === MICROSOFT_CATEGORY_SCOPE.length;
            return !allCategoryScopesGranted;
          };

          return (
            <div key={provider.provider}>
              {providerGrants.map(grant => (
                <Fragment key={`${grant.provider}-${grant.username}`}>
                  <div>
                    <Grid item xs={12} display='flex' justifyContent='space-between' paddingTop={1} paddingBottom={1}>
                      <p>Signed in to <b>{providerName}</b> as <b>{grant.username}</b></p>
                      {handleSignOut && (
                        <CabButton buttonType='tertiary' color="primary"
                          onClick={() => handleSignOut(grant)} sx={{width: 180, height: 36}}>
                          Log Out
                        </CabButton>
                      )}
                    </Grid>
                    <ProviderComponent onOAuthGranted={handleSignInDone} grant={grant} />
                  </div>
                  {showMicrosoftCategoryIncremental(grant) &&
                    <IconLinkButton
                      Icon={IoAlertOutline} 
                      onClick={handleGetMSCategoryPermissions} 
                      text={'Additional Permissions Required for Event Colors'}
                    />
                  }
                </Fragment>
              ))}

              {providerGrants.length < maxAccounts && (
                <>
                  <Grid item xs={12} display='flex' justifyContent='space-between' paddingTop={1} paddingBottom={1}>
                    {addAccount[providerName] || providerGrants.length === 0 ? (
                      <>
                        <ProviderComponent key={providerName} onOAuthGranted={handleSignInDone} />
                        {providerGrants.length > 0 && (
                          <CabButton
                            buttonType="secondary"
                            onClick={() => setAddAccount({ ...addAccount, [providerName]: false })}
                            sx={{width: 170}}
                          >
                            Cancel
                          </CabButton>
                        )}
                      </>
                    ) : (
                      <CabButton
                        buttonType="tertiary"
                        color='primary'
                        onClick={() => setAddAccount({ ...addAccount, [providerName]: true })}
                        sx={{width: 170}}
                        icon={<CabIcon Icon={IoAdd} alt='Add'/>}
                      >
                        Add Account
                      </CabButton>
                    )}
                  </Grid>
                </>
              )}
              {i < providerOptions.length - 1 && <Divider />}
            </div>
          );
        })}

      </Wrapper>
    </Grid>
  );
};

// Needed to stop tons of re-renders from happening
export const MemoizedIntegrationSection = memo(IntegrationSection, (prevProps, props) => {
  return JSON.stringify(prevProps.grants) === JSON.stringify(props.grants) 
    && JSON.stringify(prevProps.providerOptions) === JSON.stringify(props.providerOptions);
});


export default IntegrationSettings;