import { ReactElement } from 'react';
import { ThunkDispatchType, Grant } from '../../store';
import {  useDispatch } from 'react-redux';
import { MS_SIGN_IN_BUTTON } from '../../resourceUrls';
import classes from './SignIn.module.css';
import { MICROSOFT_OAUTH_REDIRECT_URI } from '../../constants';
import { getMsLoginUrl } from '../../store/auth/actions';
import { cabCaptureMessage } from '../../utils/logging';
// eslint-disable-next-line max-len
// More info: https://docs.microsoft.com/en-us/graph/tutorials/react?WT.mc_id=Portal-Microsoft_AAD_RegisteredApps&tutorial-step=3

interface ComponentProps {
  grant?: Grant;
}

type Props = ComponentProps;

export const isSafeMicrosoftUrl = (url: string) => {
  try {
    const { hostname } = new URL(url);
    const normalized = hostname.toLowerCase();
    const allowed = [
      'login.microsoftonline.com',
      'login.microsoft.com',
      'login.partner.microsoftonline.cn',
      'login.microsoftonline.de',
      'login.microsoftonline.us',
      '.onmicrosoft.com',
      '.microsoft.com'
    ];
    if (allowed.includes(normalized)) {
      return true;
    }
    // This is an imperfect check but is good enough for most cases. Vanity URLs can exist, which wouldn't be
    //   usable in this allow-list unless we added them all.
    // For example, users with Azure AD B2C and Microsoft Entra External ID can have a URL like this:
    //   https://yourtenant.b2clogin.com/yourtenant.onmicrosoft.com/oauth2/v2.0/authorize
    if (allowed.some(allowedHost => url.includes(allowedHost))) {
      cabCaptureMessage(`Potentially unsafe Microsoft URL detected: ${url}`);
      return true;
    }
  } catch { /* empty */ }
  cabCaptureMessage(`Unsafe Microsoft URL detected and blocked: ${url}`);
  return false;
};

const MicrosoftSignIn = ({
  grant
}: Props): ReactElement => {

  const dispatch = useDispatch<ThunkDispatchType>();
  
  const handleGrantOfflineClick = (): void => {
    dispatch(getMsLoginUrl(undefined, `${window.location.origin}${MICROSOFT_OAUTH_REDIRECT_URI}`)).then(authUrl => {
      if (!isSafeMicrosoftUrl(authUrl)) {
        console.error('Unsafe Microsoft URL detected:', authUrl);
        return;
      }
      window.location.href = authUrl;
    });
  };

  return (
    <div className={classes.signInContainer}>
      {!grant && (
        <div className={classes.inputContainer}>
          <p className={classes.connectText}>Connect <b>Microsoft</b></p>
          <input 
            className={classes.signInButton}
            alt='Sign in with Microsoft' 
            type='image'
            id='signIn' 
            src={MS_SIGN_IN_BUTTON}
            onClick={handleGrantOfflineClick}/>
        </div>
      )}
    </div>
  );
};

export default MicrosoftSignIn;
